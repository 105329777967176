<template>
	<div>
		<div class="contact">
			<div class="banner">
				<div class="title">
					{{ $t('contact.title') }}
				</div>
				<div class="font">
					{{ $t('contact.font1') }}
				</div>
			</div>
			<div class="body">
				<input type="text" :placeholder="$t('contact.input1')" v-model="state.form.name" />
				<input type="text" :placeholder="$t('contact.input2')" v-model="state.form.email" />
				<input type="text" :placeholder="$t('contact.input3')" v-model="state.form.phone" />
				<textarea
					:placeholder="$t('contact.input4')"
					maxlength="500"
					v-model="state.form.question"
				/>
				<div @click="go" class="button">
					{{ $t('contact.button1') }}
				</div>
			</div>
			<div class="follow">
				<div class="follow_title">
					<img
						src="https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmafcWSsa3GazhCLqr2yvWhcHDHZu8GrXkujkr4EWMMgwc"
						alt=""
					/>
					<span>
						{{ $t('contact.icon_title') }}
					</span>
				</div>
				<div class="follow_body">
					<a
						v-for="(item, index) in state.iconList"
						:key="index"
						href="https://x.com/M3DAO_global"
						target="_blank"
					>
						<img
							@mouseover="eoverLeftArrow(index + 1)"
							@mouseleave="eleaveLeftArrow(index + 1)"
							:src="`${state.http}/${item.src}.png`"
							alt=""
						/>
					</a>
				</div>
			</div>
		</div>
		<div class="flagBox" v-if="state.goFlag" @touchmove.stop.prevent>
			<div class="subBox">
				<img
					src="https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmamDaPHug8b2KM4EVQz1yiB8Tg712LHEsdvN74h5ipbsb"
					alt=""
					class="ok"
				/>
				<div class="font">
					{{ $t('contact.dialog') }}
				</div>
				<div class="button" @click="router.push('/')">
					{{ $t('contact.button2') }}
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { reactive } from 'vue';
import { submitSupport } from '@/api/index';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const router = useRouter();

const state = reactive({
	form: {
		name: '',
		email: '',
		phone: '',
		question: '',
	},
	goFlag: false,
	http: 'https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmeUkox4nkg6joPFDuMa9d3Bmp5DQcqH4mKsZyUuF9mXar',
	iconList: [
		{
			href: '',
			src: '1',
		},
		{
			href: '',
			src: '2',
		},
		{
			href: '',
			src: '3',
		},
		{
			href: '',
			src: '4',
		},
		{
			href: '',
			src: '5',
		},
	],
});
const go = () => {
	if (state.form.title != '' && state.form.question != '' && state.form.email != '') {
		submitSupport(state.form) // axios请求在这里
			.then((res) => {
				console.log(res);
				if (res.code == 200) {
					// chrome
					document.body.scrollTop = 0;
					// firefox
					document.documentElement.scrollTop = 0;
					// safari
					window.pageYOffset = 0;
					state.goFlag = true;
				}
			})
			.catch((err) => {
				console.log(err);
			});
	} else {
		return;
	}
};
const eoverLeftArrow = (item) => {
	state.iconList.map((items) => {
		if (items.src == item) {
			items.src = item + '_active';
			console.log(items.src);
		}
	});
};
const eleaveLeftArrow = (item) => {
	state.iconList.map((items) => {
		if (items.src == item + '_active') {
			items.src = item;
			console.log(items.src);
		}
	});
};
</script>

<style scoped lang="scss">
@media screen and (min-width: 768px) {
	.contact {
		width: 100%;
		background: rgba(0, 0, 0, 1);
		color: #ffffff;
		.banner {
			width: 100%;
			height: 625px;
			background: url('https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmaQHWYBeqVV8yWFAyEreb33mFGfGEDSPy4qNJcF5NnyHK')
				no-repeat;
			background-size: 1920px 625px;
			display: flex;
			flex-direction: column;
			align-items: center;
			.title {
				margin: 150px auto 18px;
				font-weight: 500;
				font-size: 50px;
				color: rgba(202, 130, 50, 1);
				line-height: 58px;
			}
			.font {
				font-weight: 400;
				font-size: 22px;
				line-height: 30px;
			}
		}
		.body {
			width: 1000px;
			height: 760px;
			margin: 0 auto 0;
			padding: 92px 88px 0;
			background: rgba(13, 13, 13, 1);
			border-radius: 19px;
			input:nth-child(1) {
				margin-top: 0;
			}
			input {
				margin-top: 30px;
				text-align: center;
				background-color: rgba(22, 22, 22, 1);
				border: 1px solid rgba(41, 41, 41, 1);
				width: 830px;
				height: 54px;
				font-weight: 400;
				font-size: 20px;
				color: #ffffff;
				box-sizing: border-box;
				&:placeholder {
					color: rgba(178, 178, 178, 1);
					font-size: 16px;
				}
			}
			textarea {
				text-align: center;
				margin: 30px 0 30px;
				outline: none;
				background-color: rgba(22, 22, 22, 1);
				border: 1px solid rgba(41, 41, 41, 1);
				font-size: 20px;
				font-weight: 400;
				color: #ffffff;
				box-sizing: border-box;
				resize: none;
				display: block;
				width: 830px;
				height: 237px;
				padding: 20px;
				&:placeholder {
					color: rgba(178, 178, 178, 1);
					font-size: 16px;
				}
			}
			.button {
				display: flex;
				justify-content: center;
				align-items: center;
				color: #000;
				font-size: 20px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
				width: 830px;
				height: 60px;
				border-radius: 6px;
				background: linear-gradient(90deg, #f37021 0%, #bb782e 100%), #bb782e;
			}
		}
		.follow {
			width: 100%;
			padding: 0 360px 440px;
			.follow_title {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-top: 150px;
				font-weight: 400;
				font-size: 56px;
				line-height: 70px;
				margin-bottom: 76px;
				img {
					width: 31px;
					height: 31px;
					margin-right: 6px;
				}
			}
			.follow_body {
				display: flex;
				justify-content: space-between;
				padding: 0 181px;
				a {
					img {
						width: 124px;
						height: 124px;
						// margin-right: 15px;
					}
				}

				// a:nth-child(5) {
				// img {
				// margin-right: 0;
				// }
				// }
			}
		}
	}
	.flagBox {
		position: absolute;
		z-index: 99;
		top: 80px;
		width: 100vw;
		height: calc(100vh - 80px);
		background: rgba(0, 0, 0, 0.8);
		.subBox {
			width: 600px;
			height: 400px;
			display: inline-flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			background: #343434;
			margin: 432px 0 0 662px;
			border-radius: 30px;
			.ok {
				width: 108px;
			}
			.font {
				margin: 37px 0;
				color: #fff;
				font-family: Lexend;
				font-size: 30px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
			}
			.button {
				width: 340px;
				height: 58px;
				display: flex;
				// padding: 10px 60px;
				justify-content: center;
				align-items: center;
				border-radius: 6px;
				background: linear-gradient(90deg, #fdaa33 0%, #1f78d3 100%);
				color: #000;
				font-size: 30px;
				font-weight: 400;
			}
		}
	}
}

@media screen and (max-width: 768px) {
	.contact {
		width: 100%;
		background: rgba(0, 0, 0, 1);
		color: #ffffff;
		.banner {
			width: 100%;
			// height: 638px;
			background: url('https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmaQHWYBeqVV8yWFAyEreb33mFGfGEDSPy4qNJcF5NnyHK')
				no-repeat;
			background-size: 375px 230px;
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-bottom: 44px;
			text-align: center;
			.title {
				margin: 145px auto 10px;
				font-weight: 500;
				font-size: 24px;
				color: rgba(202, 130, 50, 1);
				line-height: 30px;
			}
			.font {
				font-weight: 400;
				font-size: 22px;
				line-height: 30px;
			}
		}
		.body {
			width: 345px;
			height: 272px;
			margin: 0 auto 0;
			padding: 33px 24px 0;
			background: rgba(13, 13, 13, 1);
			border-radius: 19px;
			input:nth-child(1) {
				margin-top: 6px;
			}
			input:nth-child(2) {
				margin-top: 6px;
			}
			input:nth-child(3) {
				margin-top: 6px;
			}
			input {
				box-sizing: border-box;
				text-align: center;
				background-color: rgba(22, 22, 22, 1);
				border: 1px solid rgba(41, 41, 41, 1);
				width: 298px;
				width: 100%;
				height: 20px;
				font-weight: 400;
				font-size: 10px;
				color: #ffffff;
				&:placeholder {
					color: rgba(178, 178, 178, 1);
					font-size: 16px;
				}
			}
			textarea {
				text-align: center;
				margin: 6px 0 30px;
				outline: none;
				background-color: rgba(22, 22, 22, 1);
				border: 1px solid rgba(41, 41, 41, 1);
				font-size: 10px;
				font-weight: 400;
				color: #ffffff;
				box-sizing: border-box;
				resize: none;
				display: block;
				width: 298px;
				width: 100%;
				height: 85px;
				// padding: 10px;
				&:placeholder {
					color: rgba(178, 178, 178, 1);
					font-size: 16px;
				}
			}
			.button {
				display: flex;
				justify-content: center;
				align-items: center;
				color: #000;
				font-size: 10px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
				width: 294px;
				height: 22px;
				border-radius: 6px;
				background: linear-gradient(90deg, #f37021 0%, #bb782e 100%), #bb782e;
			}
		}
		.follow {
			width: 100%;
			padding: 0 32px 100px;
			.follow_title {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				margin-top: 75px;
				font-weight: 600;
				font-size: 28px;
				line-height: 35px;
				margin-bottom: 50px;
				img {
					width: 11px;
					height: 11px;
				}
			}
			.follow_body {
				display: flex;
				justify-content: space-between;
				a {
					img {
						width: 46px;
						height: 46px;
						// margin-right: 15px;
					}
				}

				// a:nth-child(5) {
				// img {
				// margin-right: 0;
				// }
				// }
			}
		}
	}
	.flagBox {
		position: absolute;
		z-index: 99;
		top: 50px;
		width: 100vw;
		height: 100vh;
		background: rgba(0, 0, 0, 0.8);
		padding: 130px 37px 0;
		.subBox {
			width: 300px;
			height: 150px;
			display: inline-flex;
			flex-direction: column;
			// justify-content: center;
			align-items: center;
			background: #343434;
			border-radius: 30px;
			padding: 20px 0;
			.ok {
				width: 54px;
			}
			.font {
				margin: 37px 0;
				color: #fff;
				font-family: Lexend;
				font-size: 20px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
			}
			.button {
				margin-top: 30px;
				width: 300px;
				height: 50px;
				line-height: 50px;
				text-align: center;
				// display: flex;
				// justify-content: center;
				// align-items: center;
				border-radius: 6px;
				background: linear-gradient(90deg, #fdaa33 0%, #1f78d3 100%);
				color: #000;
				font-size: 20px;
				font-weight: 400;
			}
		}
	}
}
</style>
